import { Admin, CustomRoutes, Layout, defaultTheme } from "react-admin";
import { Route } from "react-router-dom";

import polyglotI18nProvider from "ra-i18n-polyglot";

import { Configuration } from "../config";
import { authProvider } from "../core/AuthProvider";
import { APIDataProvider } from "../core/DataProvider";
import { customPortugueseMessages } from "../i18n/pt-BR";
import { AdminAppBar } from "./AppBar";
import { AppMenu } from "./Menu";
import { AppControlPage } from "./pages/AppControlPage";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/LoginPage";
import { QRCodeLogin } from "./pages/QRCodeLogin";
import { ResultGeneratorPage } from "./pages/ResultGeneratorPage";
import { MercadoPagoMarketplaceConnector } from "./pages/gateways/mercadopago/MarketplaceConnector";
import { ResourceWithPermissions } from "./parts/ResourceWithPermission";
import { AffiliateActivityResource, AffiliatesResource, ResellersMaterialResource } from "./resources/Affiliates";
import { AmbassadorsResource } from "./resources/Ambassadors";
import { ApprovedPaymentsWithoutNumbersResource } from "./resources/ApprovedPaymentsWithoutNumbers";
import { CouponsResource } from "./resources/Coupons";
import { DailyRafflesResource } from "./resources/DailyRaffles";
import { FederalLottoRafflePrizesAndWinnersResource, FederalLottoRafflesResource } from "./resources/FederalLottoRaffles";
import { FinancialsResource } from "./resources/Financials";
import { ManagedBatchesResource } from "./resources/ManagedBatches";
import { MatrixesResource } from "./resources/Matrixes";
import { ConsumersResource } from "./resources/Partners";
import { PaymentsResource } from "./resources/Payments";
import { RaffleCategoriesResource } from "./resources/RaffleCategories";
import { RaffleExternalResultsResource, RaffleLuckyNumbersResource, RafflePrizesResource, RaffleResultsResource, RafflesResource } from "./resources/Raffles";
import { UserLuckyNumbersResource, UsersResource } from "./resources/Users";
import { AdminMFA } from "./resources/admins/AdminMFA";
import { DailyRaffleRegistration } from "./resources/daily-raffles/DailyRaffleRegistration";
import { ConteSuaHistoriaResource } from "./resources/forms/conte-sua-historia/ContaSuaHistoria";
import { AdminsResource } from "./resources/system/AdminsResource";
import { DatabaseResource } from "./resources/system/Database";
import { NotificationsResource } from "./resources/system/Notifications";
import { PluginsResource } from "./resources/system/Plugins";

const i18nProvider = polyglotI18nProvider(() => customPortugueseMessages as any, "pt-br");

export const OurLayout = (props: any) => <Layout {...props} menu={AppMenu} appBar={AdminAppBar} />

defaultTheme.sidebar.width = 275;
defaultTheme.sidebar.closedWidth = 40;
(defaultTheme.palette as any).primary = {
    main: "#2f4eb5"
};

export const App = () => (
    <Admin
        title={Configuration.title ?? "LuckyMaker"}
        dataProvider={APIDataProvider}
        i18nProvider={i18nProvider}
        authProvider={authProvider}
        dashboard={Dashboard}
        theme={defaultTheme}
        layout={OurLayout}
        loginPage={<Login />}

        requireAuth
        disableTelemetry
    >
        {
            (permissions) => {
                return [
                    <CustomRoutes>
                        <Route path="/helpers/result-image-generator" element={<ResultGeneratorPage />} />
                    </CustomRoutes>,

                    <CustomRoutes>
                        <Route path="/helpers/app-control" element={<AppControlPage />} />
                    </CustomRoutes>,

                    <CustomRoutes>
                        <Route path="/user/add-device" element={<QRCodeLogin />} />
                        <Route path="/user/mfa" element={<AdminMFA />} />
                    </CustomRoutes>,

                    <CustomRoutes>
                        <Route path="/gateways/mercadopago/marketplace/connector" element={<MercadoPagoMarketplaceConnector />} />
                    </CustomRoutes>,

                    ResourceWithPermissions({
                        permissions: permissions,
                        name: "admins",
                        ...AdminsResource
                    }),

                    ResourceWithPermissions({
                        permissions: permissions,
                        name: "financials",
                        ...FinancialsResource
                    }),

                    ResourceWithPermissions({
                        permissions: permissions,
                        name: "affiliates",
                        ...AffiliatesResource
                    }),

                    ResourceWithPermissions({
                        permissions: permissions,
                        name: "affiliateActivity",
                        ...AffiliateActivityResource
                    }),

                    ResourceWithPermissions({
                        permissions: permissions,
                        name: "resellersMaterial",
                        ...ResellersMaterialResource
                    }),

                    ResourceWithPermissions({
                        permissions: permissions,
                        name: "managedPhysicalRanges",
                        ...ManagedBatchesResource
                    }),

                    ResourceWithPermissions({
                        permissions: permissions,
                        name: "notifications",
                        ...NotificationsResource
                    }),

                    ResourceWithPermissions({
                        permissions: permissions,
                        name: "matrix",
                        ...MatrixesResource
                    }),

                    ResourceWithPermissions({
                        permissions: permissions,
                        name: "raffles",
                        ...RafflesResource
                    }),

                    ResourceWithPermissions({
                        permissions: permissions,
                        name: "raffleCategories",
                        ...RaffleCategoriesResource
                    }),

                    ResourceWithPermissions({
                        permissions: permissions,
                        name: "dailyRaffles",
                        ...DailyRafflesResource
                    }),

                    <CustomRoutes>
                        <Route path="/dailyRaffles/register" element={<DailyRaffleRegistration />} />
                    </CustomRoutes>,

                    ResourceWithPermissions({
                        permissions: permissions,
                        name: "rafflePrizes",
                        ...RafflePrizesResource
                    }),

                    ResourceWithPermissions({
                        permissions: permissions,
                        name: "raffleResults",
                        ...RaffleResultsResource
                    }),

                    ResourceWithPermissions({
                        permissions: permissions,
                        name: "raffleExternalResults",
                        ...RaffleExternalResultsResource
                    }),

                    ResourceWithPermissions({
                        permissions: permissions,
                        name: "raffleLuckyNumbers",
                        ...RaffleLuckyNumbersResource
                    }),

                    ResourceWithPermissions({
                        permissions: permissions,
                        name: "payments",
                        ...PaymentsResource
                    }),

                    ResourceWithPermissions({
                        permissions: permissions,
                        name: "coupons",
                        ...CouponsResource
                    }),

                    ResourceWithPermissions({
                        permissions: permissions,
                        name: "users",
                        ...UsersResource
                    }),
                    ResourceWithPermissions({
                        permissions: permissions,
                        name: "userLuckyNumbers",
                        ...UserLuckyNumbersResource
                    }),

                    ResourceWithPermissions({
                        permissions: permissions,
                        name: "federalLottoRaffles",
                        ...FederalLottoRafflesResource
                    }),
                    ResourceWithPermissions({
                        permissions: permissions,
                        name: "federalLottoRafflePrizes",
                        ...FederalLottoRafflePrizesAndWinnersResource
                    }),

                    ResourceWithPermissions({
                        permissions,
                        name: "approvedPaymentsWithoutNumbers",
                        ...ApprovedPaymentsWithoutNumbersResource
                    }),

                    ResourceWithPermissions({
                        permissions,
                        name: "formSubmissions",
                        ...ConteSuaHistoriaResource
                    }),

                    ResourceWithPermissions({
                        permissions,
                        name: "database",
                        ...DatabaseResource
                    }),

                    ResourceWithPermissions({
                        permissions,
                        name: "plugins",
                        ...PluginsResource
                    }),

                    ResourceWithPermissions({
                        permissions,
                        name: "partners",
                        ...ConsumersResource
                    }),

                    ResourceWithPermissions({
                        permissions,
                        name: "ambassadors",
                        ...AmbassadorsResource
                    })
                ];
            }
        }
    </Admin>
);
