import { Datagrid, List, TextField } from "react-admin";

export const RaffleCategoriesList = () => (
    <List sort={{ field: "displayOrder", order: "asc" }}>
        <Datagrid rowClick="show">
            <TextField source="label" />

            <TextField source="slug" />

            <TextField source="rafflesCount" />

            <TextField source="displayOrder" />
        </Datagrid>
    </List>
);
