import { TextField, Show, TabbedShowLayout, Tab, NumberField, SelectField, TopToolbar, Button, EditButton, useShowContext, useNotify } from "react-admin";
import { createActionRequest } from "../../../core/Actions";
import { AffiliateRegistrationStatus } from "../Affiliates";
import { ResellerRoles } from "../../../core/ResellerRoles";

const AffiliateShowActions = () => {
    const { record, refetch } = useShowContext();

    // If the record somehow is falsy, avoid rendering.
    if (!record) {
        return <></>;
    }

    const notify = useNotify();

    const isPending = record.registrationStatus === "PENDING";
    const isRefused = record.registrationStatus === "REFUSED";
    const isApproved = record.registrationStatus === "APPROVED";
    const isBlocked = record.registrationStatus === "BLOCKED";

    return (
        <TopToolbar>
            <>
                {
                    (isPending || isRefused)
                    && <Button
                        label={ "Aprovar" }
                        onClick={ async () => {
                            await createActionRequest("post", "affiliates", record.id, "setApproved", { approved: true });
                            notify("Revendedor aprovado.", { type: "success" });
                            refetch!();
                        }}
                    />
                }
                {
                    isPending
                    && <Button
                        label={ "Recusar" }
                        onClick={ async () => {
                            await createActionRequest("post", "affiliates", record.id, "setApproved", { approved: false });
                            notify("Revendedor recusado.", { type: "success" });
                            refetch!();
                        }}
                    />
                }
                {
                    (isBlocked || isApproved)
                    && <Button
                        label={ isBlocked ? "Desbloquear" : "Bloquear" }
                        onClick={
                            async () => {
                                const wasBlocked = Boolean(isBlocked);
                                await createActionRequest("post", "affiliates", record.id, "setBlocked", { blocked: !wasBlocked });

                                if (wasBlocked) {
                                    notify("Desbloqueado.", { type: "success" });
                                } else {
                                    notify("Bloqueado.", { type: "success" });
                                }
                                refetch!();
                            }
                        }
                    />
                }
                <EditButton/>
            </>
        </TopToolbar>
    );
};

export const AffiliateShow = () => (
    <Show actions={<AffiliateShowActions />}>
        <TabbedShowLayout>
            <Tab label="Dados do revendedor">
                <SelectField source="role" choices={ResellerRoles} />

                <TextField fullWidth source="name" />

                <TextField fullWidth source="email" />

                <TextField fullWidth source="tel" />

                <TextField fullWidth source="slug" />

                <SelectField source="registrationStatus" choices={AffiliateRegistrationStatus} />
            </Tab>

            <Tab label="Dados financeiros">
                <TextField fullWidth source="data.PIXKey" />
            </Tab>

            <Tab label="Estatísticas">
                <NumberField fullWidth source="funds.balance" options={{ style: "currency", currency: "BRL" }} />

                <NumberField fullWidth source="funds.bruteTotal" options={{ style: "currency", currency: "BRL" }} />

                <NumberField fullWidth source="funds.netTotal" options={{ style: "currency", currency: "BRL" }} />

                <NumberField fullWidth source="funds.withdrawed" options={{ style: "currency", currency: "BRL" }} />
            </Tab>
        </TabbedShowLayout>
    </Show>
);
