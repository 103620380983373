import { AutocompleteArrayInput, DateTimeInput, NumberInput, ReferenceArrayInput, SimpleForm } from "ra-ui-materialui";
import { minValue, required, SelectInput } from "react-admin";

const BatchesKinds = [
    { id: "NORMAL_SALE", name: "Venda normal" },
    { id: "FAST_SALE", name: "Venda rápida" }
];

export const ManagedBatchesForm = (props: { mode: string }) => {
    return (
        <SimpleForm>
            <div className="flex gap-3">
                <NumberInput source="fromBatch" validate={[required(), minValue(1)]} />
                <NumberInput source="toBatch" validate={[required(), minValue(1)]} />
            </div>

            <NumberInput source="bondsPerBatch" validate={[required(), minValue(1)]} />

            <DateTimeInput
                source="expiresAt"
                validate={[...(props.mode === "edit" ? [required()] : [])]}
                label={props.mode === "create" ? "Data de expiração" : undefined}
                helperText={props.mode === "create" ? "O sistema preencherá automaticamente caso não informado" : "Obrigatório"}
            />

            <ReferenceArrayInput reference="raffleCategories" source="filters.categories">
                <AutocompleteArrayInput fullWidth optionText="label" />
            </ReferenceArrayInput>

            <SelectInput
                source="kind"
                choices={ BatchesKinds }
                fullWidth
                validate={[required()]}
                defaultValue={ "NORMAL_SALE" }
            />
        </SimpleForm>
    );
}
