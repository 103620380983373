import StraightenIcon from "@mui/icons-material/Straighten";
import { ManagedBatchesList } from "./affiliates/managedBatches/ManagedBatchesList";
import { ManagedBatchesShow } from "./affiliates/managedBatches/ManagedBatchesShow";
import { ManagedBatchesCreate } from "./affiliates/managedBatches/ManagedBatchesCreate";
import { ManagedBatchesEdit } from "./affiliates/managedBatches/ManagedBatchesEdit";

export const ManagedBatchesResource = {
    show: ManagedBatchesShow,
    create: ManagedBatchesCreate,
    edit: ManagedBatchesEdit,
    list: ManagedBatchesList,
    icon: StraightenIcon
};
