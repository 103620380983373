import { Typography } from "@mui/material";
import { Button, ChipField, Datagrid, DateField, DeleteButton, EditButton, FunctionField, NumberField, Pagination, ReferenceArrayField, ReferenceField, ReferenceManyField, Show, SimpleShowLayout, SingleFieldList, TextField, TopToolbar, useNotify, useRefresh, useShowContext } from "react-admin";
import { createActionRequest } from "../../../../core/Actions";
import { EmptyListBody } from "../../../parts/EmptyListBody";
import { RecordConsumer } from "../../../parts/RecordConsumer";

import { generateAuthenticatedURL } from "../../../../utils/RequestUtils";

/**
 * Generates a list of URLs to a file.
 * @param megaBatchId The mega-batch ID.
 * @param batchId The batch ID.
 * @param fromBatch The number to start from.
 * @param toBatch The number to end in.
 */
function generateUrlsToFile(megaBatchId: number) {
    const url: URL = new URL(
        generateAuthenticatedURL("/managedPhysicalRanges/" + megaBatchId + "/actions/export")
    );

    window.open(url);
}

const ResetButton = () => {
    const { record } = useShowContext();
    const notify = useNotify();
    const refresh = useRefresh();

    const clearDistributions = async () => {
        if (window.confirm(`Deseja apagar distribuições do lote ${record.id}? O gerente deverá recadastrar todos os revendedores.`)) {
            await createActionRequest("post", "managedPhysicalRanges", record.id, "reset");

            notify("Distribuições apagadas. Note que a data de expiração NÃO foi alterada.", { type: "success" });
            refresh();
        }
    };

    const renew = async () => {
        if (window.confirm(`Deseja renovar a expiração do lote ${record.id}?`)) {
            await createActionRequest("post", "managedPhysicalRanges", record.id, "renew");

            notify("Data de expiração renovada. Verifique a nova data.", { type: "success" });
            refresh();
        }
    };

    return (
        <>
            <Button label="Apagar distribuições" onClick={clearDistributions} />
            <Button label="Renovar expiração" onClick={renew} />
        </>
    )
}

const GenerateUrlsButton = () => {
    const { record } = useShowContext();

    const onClick = async () => {
        generateUrlsToFile(record.id);
    };

    return (
        <>
            <Button label="Exportar URLs" onClick={onClick} />
        </>
    )
}

const ManagedBatchActions = () => {
    return (
        <TopToolbar>
            <ResetButton />
            <GenerateUrlsButton />

            <EditButton />
            <DeleteButton />
        </TopToolbar>
    );
}
export const ManagedBatchesShow = () => (
    <Show
        actions={<ManagedBatchActions />}
    >
        <SimpleShowLayout>
            <TextField source="fromBatch" />
            <TextField source="toBatch" />

            <FunctionField
                source="kind"
                render={(val) => val.kind === "FAST_SALE" ? "Venda rápida" : "Venda normal"}
            />

            <TextField source="batchesCount" sortable={false} />
            <NumberField source="bondsPerBatch" />
            <DateField source="expiresAt" showTime={true} />
            <DateField source="lastResetAt" showTime={true} emptyText="Não houve" />
            <FunctionField source="occupancyRatio" sortable={false} render={(val) => (Number(val.occupancyRatio) * 100).toFixed(0) + "%"} />

            <ReferenceArrayField reference="raffleCategories" source="filters.categories" emptyText="Nenhuma">
                <SingleFieldList>
                    <ChipField source="label" />
                </SingleFieldList>
            </ReferenceArrayField>

            <RecordConsumer>
                {(record) =>
                    <div className="my-6">
                        <div className="flex flex-wrap justify-between md:flex-nowrap">
                            <Typography variant="h6">
                                Distribuições deste lote
                            </Typography>
                        </div>

                        <div className="mt-6">
                            <ReferenceManyField sort={{ field: "batchNumber", order: "ASC" }} pagination={<Pagination />} reference="affiliatePhysicalRanges" target="managedRangeId" filter={{ managedRangeId: record.id }}>
                                <Datagrid rowClick={undefined} bulkActionButtons={false} empty={<EmptyListBody label="Ainda não há revendedores associados. O gerente já pode adicionar revendedores." />}>
                                    <TextField source="batchNumber" label="Lote" />

                                    <ReferenceField reference="affiliates" source="affiliateId">
                                        <TextField source="name" />
                                    </ReferenceField>

                                    <ReferenceField reference="affiliates" source="managerId">
                                        <TextField source="name" />
                                    </ReferenceField>
                                </Datagrid>
                            </ReferenceManyField>
                        </div>
                    </div>
                }
            </RecordConsumer>
        </SimpleShowLayout>
    </Show>
);
