import { RichTextInput } from "ra-input-rich-text";
import { AutocompleteInput, DateTimeInput, FormDataConsumer, FormTab, ImageField, ImageInput, NumberInput, ReferenceInput, required, SelectInput, TabbedForm, TextInput, useTranslate } from "react-admin";
import { ColorInput } from "react-admin-color-picker";
import { useWatch } from "react-hook-form";
import { RaffleKinds, RaffleStatus } from "../Raffles";
import { RaffleEntropyTab } from "./tabs/RaffleEntropyTab";

function sanitizeSlug(value: string) {
    return value?.replace(/[^A-zÀ-ú0-9\s-_]/g, "")
        .replace(/\s/g, "-")
        .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");
}

export const SlugInput = (...props: any[]) => {
    const title = useWatch({ name: "title" });

    return (
        <TextInput
            fullWidth
            source="slug"
            format={(value) => value ? sanitizeSlug(value): sanitizeSlug(title)}
            value={sanitizeSlug(title)}
            validate={[required()]}
            helperText="O nome utilizado para identificar este sorteio."
            {...props}
        />
    );
}

export const RaffleForm = () => {
    const translate = useTranslate();

    return (
        <>
            <style>{/*css*/`
                .RaRichTextInput-editorContent .ProseMirror {
                    min-height: 200px;
                }
            `}
            </style>

            <TabbedForm>
                <FormTab label={translate("resources.raffles.tabs.data")}>
                    <TextInput fullWidth source="title" validate={[required()]} />

                    <SelectInput
                        fullWidth
                        source="kind"
                        choices={RaffleKinds}
                        validate={[required()]}
                    />

                    <TextInput fullWidth source="edition" validate={[required()]} />

                    <RichTextInput fullWidth source="description" validate={[required()]} />

                    <TextInput multiline fullWidth source="excerpt" validate={[required()]} />

                    <ReferenceInput
                        source="categoryId"
                        reference="raffleCategories"
                    >
                        <AutocompleteInput
                            fullWidth
                            optionText="slug"
                            label="Categoria"
                            clearIcon={false}
                        />
                    </ReferenceInput>

                    <ImageInput
                        fullWidth
                        accept="image/png, image/jpg, image/jpeg"
                        source="thumbnail"
                    >
                        <ImageField
                            fullWidth
                            source="src"
                            title="title"
                        />
                    </ImageInput>

                    <SlugInput />

                    <FormDataConsumer>
                        {({ formData }) => {
                            const isGamblingRaffle = formData?.kind === "GAMBLING";

                            return isGamblingRaffle ? null :
                                <NumberInput
                                    fullWidth
                                    source="price"
                                    options={{ style: "currency", currency: "BRL" }}
                                    validate={[required()]}
                                />
                        }}
                    </FormDataConsumer>

                    <SelectInput fullWidth source="status" validate={[required()]} choices={RaffleStatus} />

                    <TextInput fullWidth source="settings.susepProcess" />
                    <RichTextInput fullWidth source="settings.regulation" />

                    <DateTimeInput helperText="A data e hora no qual o sorteio inicia as vendas." fullWidth source="startAt" validate={[required()]} />
                    <DateTimeInput helperText="A data e hora no qual o sorteio encerra as vendas." fullWidth source="endAt" validate={[required()]} />

                    <DateTimeInput helperText="A data e hora no qual o sorteio será realizado." fullWidth source="drawDate" validate={[required()]} />
                </FormTab>

                <FormTab label={translate("resources.raffles.tabs.entropy")}>
                    <RaffleEntropyTab />
                </FormTab>

                <FormTab label={translate("resources.raffles.tabs.style")}>
                    <ColorInput
                        source="settings.colors.highlight"
                        picker="Sketch"
                    />
                </FormTab>

                <FormTab label={translate("resources.raffles.tabs.gambling")}>
                    <GamblingFormTab />
                </FormTab>

                <FormTab label={translate("resources.raffles.tabs.live")}>
                    <TextInput fullWidth type="url" source="settings.whereToWatch.facebook" />
                    <TextInput fullWidth type="url" source="settings.whereToWatch.youtube" />
                    <TextInput fullWidth type="url" source="settings.whereToWatch.twitch" />
                </FormTab>
            </TabbedForm>
        </>
    );
}

const GamblingFormTab = () => {
    return <FormDataConsumer>
        {({ formData }) => {
            const isGamblingRaffle = formData?.kind === "GAMBLING";

            return isGamblingRaffle && <>
                <NumberInput
                    fullWidth
                    source="price"
                    label="Valor mínimo de aposta"
                    options={{ style: "currency", currency: "BRL" }}
                    validate={[required()]}
                    helperText="O valor do sorteio e também o valor mínimo de aposta que poderá ser investido em um título."
                />

                <TextInput
                    fullWidth
                    type="number"
                    source="settings.gambling.maximumBetPrice"
                    validate={[required()]}
                    helperText="O valor máximo de aposta que poderá ser investido em um título."
                />

                <NumberInput
                    fullWidth
                    source="settings.gambling.maximumMultiplier"
                    label="Dezena máxima"
                    validate={[required()]}
                    helperText="O máximo de dezenas a ser sorteado."
                />
            </>
        }}
    </FormDataConsumer>
}
